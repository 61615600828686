<template>
  <ValidationObserver ref="observe">
    <b-form>
      <!--form add notification-->
      <ek-dialog
        ref="addDialog"
        title="ارسال إشعار"
        size="md"
        placeholder="ابحث عن اشعار محدد"
        btnText=" إشعار جديد"
        @ok="submit()"
        @close="reset"
        @search="setSearch"
      >
        <template #body>
          <label> ارسال إلى :</label>
          <b-form-radio-group
            v-model="selected"
            :options="options"
            value-field="item"
            text-field="name"
          ></b-form-radio-group>

          <h4 v-if="selected == 0" class="mt-1">فلتر حسب:</h4>
          <ek-input-select
            v-if="selected == 0"
            @change="onChange($event)"
            name="sendTo"
            multiple
            placeholder="حدد  نوع الكلية"
            label="الكلية  "
            textLabel="followTo"
            :options="facultyName"
            v-model="notificationDto.facultyIds"
          ></ek-input-select>

          <h4 v-if="selected == 0">ارسال إلى:</h4>
          <b-form-checkbox
            ref="select"
            v-if="selected == 0"
            v-model="allSelected"
            @change="studentAll"
            >جميع الطلاب
          </b-form-checkbox>

          <ek-input-select
            v-if="selected == 0"
            name="sendTo"
            multiple
            placeholder="حدد الطلاب"
            label="اختر طالب"
            :options="studentName"
            v-model="notificationDto.sendToIds"
          ></ek-input-select>

          <h4 v-if="selected == 1" class="mt-1">ارسال إلى:</h4>
          <b-form-checkbox
            ref="select"
            v-if="selected == 1"
            v-model="allSelected"
            @change="sellPointAll"
            >جميع نقاط البيع
          </b-form-checkbox>

          <ek-input-select
            v-if="selected == 1"
            name="sendTo"
            multiple
            placeholder="حدد نقاط البيع"
            label="اختر نقطة بيع"
            :options="sellPointName"
            v-model="notificationDto.sendToIds"
          ></ek-input-select>

          <ek-input-text
            :rules="[{ type: 'required', message: 'العنوان مطلوب' }]"
            name="title"
            placeholder="ادخل عنوان الإشعار  "
            label=" عنوان الإشعار "
            v-model="notificationDto.title"
          ></ek-input-text>

          <ek-input-textarea
            :rules="[{ type: 'required', message: 'النص  مطلوب' }]"
            name="phoneNumber"
            placeholder="أدخل النص"
            label="نص الإشعار "
            v-model="notificationDto.body"
          ></ek-input-textarea>
        </template>
      </ek-dialog>
    </b-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  components: {
    ValidationObserver,
  },
  computed: {
    ...mapState({
      notificationDto: (state) => state.notifications.notificationDto,
      sellPointName: (state) => state.sellPoints.sellPointName,
      facultyName: (state) => state.faculty.facultyName,
      studentName: (state) => state.students.studentName,
    }),
    ...mapGetters(["notificationsList"]),
  },
  data() {
    return {
      selecte: [],
      allSelected: false,
      selected: 0,
      options: [
        { item: 0, name: "الطلاب" },
        { item: 1, name: "نقاط البيع" },
      ],
    };
  },
  methods: {
    ...mapActions(["addNotifications", "addStudentNotifications", "getStudentsName"]),
    ...mapMutations(["Reset_Notifications_Dto"]),
    //submit add notification
    submit() {
      this.$refs.observe.validate().then((success) => {
        if (success) {
          if (this.selected == 0) {
            this.addStudentNotifications(this.notificationDto);
            this.$refs.addDialog.close();
            this.$refs.observe.reset();
          } else {
            this.addNotifications(this.notificationDto);
            this.$refs.addDialog.close();
            this.$refs.observe.reset();
          }
        }
      });
    },

    //check all sellPoint
    sellPointAll(checked) {
      this.selecte = checked ? this.sellPointName.slice() : [];
      this.notificationDto.sendToIds = this.selecte.map((el) => el.id);
    },

    //check add student
    studentAll(checked) {
      this.selecte = checked ? this.studentName.slice() : [];
      this.notificationDto.sendToIds = this.selecte.map((el) => el.id);
    },
    onChange(studentId) {
      this.getStudentsName(studentId);
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["title", "body", "notificationType", "date"],
        query,
      });
    },
    reset() {
      this.$refs["observe"].reset();
      Object.assign(this.notificationDto, {
        title: "",
        body: "",
        sendToIds: "",
        facultyIds: [],
        selecte: [""],
        allSelected: false,
      });
    },
  },
};
</script>
